/**
 * TODO - Avoid hardcoded links. User NEXT_PUBLIC_env vars instead
 */
export function diagnosticLink() {
    const isStaging = process.env.NEXT_PUBLIC_NENV === 'staging';
    const isDevelopment = process.env.NEXT_PUBLIC_NENV === 'development';
    if (isDevelopment) {
        return ('http://app.dev.sunnyside.co/diagnostic/HJmDjOmw/intro');
    } else if (isStaging) {
        return ('https://app.staging.sunnyside.co/diagnostic/HJmDjOmw/intro');
    } 
    return ('https://app.sunnyside.co/diagnostic/HJmDjOmw/intro');
}
