import React, { forwardRef, useEffect, useState } from 'react';
import { Box } from  '@mui/material';
import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { colors } from '../../styles/_colors';
import SunnysidePineLogo from '../../../public/icons/sunnyside/sunnyside-logo-pine';
import SunnysideWhiteLogo from '../../../public/icons/sunnyside/sunnyside-logo-white';
import SunnysideLink from '../link';
import SunnysideButton from '../button';
import SunnysideSelector from '../selector';
import router, { useRouter } from 'next/router';
import { Media, MediaContextProvider } from '../../styles/media';
import { diagnosticLink } from '../../utils/diagnosticLinks';
import { useCookies } from 'react-cookie';

type SunnysideHeaderProps = {
    bgcolor?: string,
    iconColor?: string,
    linkColor?: string,
    whiteLogo?: boolean,
    noNav?: boolean,
    ctaBtn?: {
        bgColor?: string,
        uri?: string,
        onClick?: () => void,
        passQueryToUrl?: boolean
    }
}
export default function SunnysideHeader(props: SunnysideHeaderProps): JSX.Element {
    const { bgcolor, iconColor, linkColor, whiteLogo, noNav } = props;
    const [scroll, setScroll] = useState(false);
    const [ cookies, setCookie ] = useCookies();
    const [logoUrl, setLogoUrl] = useState('/');
    const isStaging = process.env.NEXT_PUBLIC_NENV === 'staging';
    const isDevelopment = process.env.NEXT_PUBLIC_NENV === 'staging';

    const HeaderGrid = styled(Grid)<GridProps>(({ theme }) => ({
        background: bgcolor || colors.cloud,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: 'none',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        [theme.breakpoints.down('sm')]: {
            padding: '1.5rem 1.25rem',
            height: '4rem'
        },
        [theme.breakpoints.up('sm')]: {
            padding: '1.5rem 1.25rem',
            height: '5.5rem'
        },
        [theme.breakpoints.up('md')]: {
            padding: '2.5rem 2rem',
            height: '5.5rem',
        },
        transition: 'background 250ms ease',
        '&.scroll': {
            boxShadow: '0px 3.74866px 16.3px rgba(163, 163, 163, 0.3)',
            background: bgcolor || colors.cloud,
        },
    }));

    useEffect(() => {
        const handleScroll = () => {
            if (typeof window !== 'undefined') {
                const currentPosition = window.scrollY;
                setScroll(currentPosition > 30);
            }
        };
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    
    type LogoProps = {
        onClick?: () => void, 
        href?: string
    }
    const Logo = forwardRef<HTMLAnchorElement, LogoProps>(({ onClick, href }, ref) => {
        if (whiteLogo) {
            return (
                <>
                    <Media greaterThanOrEqual='sm'>
                        <a href={href} onClick={onClick} ref={ref}>
                            <SunnysideWhiteLogo 
                                width={168}
                                height={53}
                            />
                        </a>
                    </Media>
                    <Media lessThan='sm'>
                        <a href={href} onClick={onClick} ref={ref}>
                            <SunnysideWhiteLogo 
                                width={126}
                                height={40}
                            />
                        </a>
                    </Media>
                </>
            );
        } else {
            return (
                <MediaContextProvider>
                    <Media lessThan='sm'>
                        <a href={href} onClick={onClick} ref={ref}>
                            <SunnysidePineLogo 
                                width={126}
                                height={40}
                            />
                        </a>
                    </Media>
                    <Media greaterThanOrEqual='sm'>
                        <a href={href} onClick={onClick} ref={ref}>
                            <SunnysidePineLogo 
                                width={168}
                                height={53}
                            />
                        </a>
                    </Media>
                </MediaContextProvider>
            );
        }
    });
    Logo.displayName = 'Logo';
    Logo.propTypes = {
        onClick: PropTypes.func,
        href: PropTypes.string
    };

    const options = [
        'About',
        'Blog',
        'Reviews',
        'Log In',
    ];

    const optionLinks = [
        '/about',
        '/blog',
        '/reviews',
        isDevelopment ? 'http://app.dev.sunnyside.co/login/phone' : (isStaging ? 'https://app.staging.sunnyside.co/login/phone' : 'https://app.sunnyside.co/login/phone')
    ];

    const mobileOptions = options.concat('Get Started');
    const mobileLinks = optionLinks.concat(props.ctaBtn?.uri ?? diagnosticLink());

    const clientRouter = useRouter();
    const pathname = clientRouter.asPath;
    const checkActive = (option) => {
        return pathname.includes(option.toLowerCase());
    };

    const handleOnClick = (event) => {
        if (event.target && event.target.text === 'Blog') {
            router.push('/blog');
        }
    };

    return (
        <HeaderGrid role='header' 
            className={`header ${scroll ? 'scroll' : ''}`}
        > 
            <Link href={logoUrl} passHref prefetch={false}>
                <Logo />
            </Link>
            {!noNav && 
            <MediaContextProvider>
                <Media lessThan="md">
                    <SunnysideSelector 
                        options={mobileOptions} 
                        optionLinks={mobileLinks} 
                        menuWidth={'calc(100vw - 2.1875rem)'}
                        iconColor={iconColor}
                        onClick={(event) => handleOnClick(event)}
                        icon/>
                </Media>
                <Media greaterThanOrEqual="md">
                    <Box display="flex" width="36rem" justifyContent="space-between" alignItems='center'>
                        {options.map((option, index) => {
                            return (
                                <SunnysideLink key={index} 
                                    onClick={(event) => handleOnClick(event)}
                                    href={optionLinks[index]} 
                                    color={linkColor || colors.pine}
                                    className={checkActive(option) && 'active-nav-link'}>
                                    {option}
                                </SunnysideLink>
                            );
                        })}
                        <SunnysideButton
                            padding='6px 26px'
                            width='fit-content' 
                            href={props.ctaBtn?.uri ?? diagnosticLink()}
                            passQueryToUrl={props.ctaBtn?.passQueryToUrl}
                            onClick={props.ctaBtn?.onClick}
                            bgcolor={props.ctaBtn?.bgColor}
                            hovercolor={props.ctaBtn?.bgColor ? `${props.ctaBtn.bgColor}80` : undefined}
                            textcolor={colors.cloud}>Get Started</SunnysideButton>
                    </Box>
                </Media>
            </MediaContextProvider>
            }
        </HeaderGrid>
    );
}
