import analytics from './analytics';
import { getEventMetaData } from './utils';

/*
user_id is no longer a required attribute. 
user_id is used to associate that device/session with our unique UUID
user_id should not be set other than at user creation and login
user_id should not be set if we don't know what the user_id is 
(null users get grouped as one user and it leads to merge issues)
*/
export function ampLogEvent(event: string, payload?: any, cookies?: {[name: string]: any} | undefined) {
    const { event_source, event_device } = getEventMetaData(cookies);
    analytics.track(event, {
        ...payload,
        event_source,
        event_device,
        analytics_version: 'v2'
    });
}

export function ampIdentifySet(key: string, value: string) {
    if(process.browser && window['amplitude']) {
        const identify = new window['amplitude'].Identify().set(key, value);
        window['amplitude'].getInstance().identify(identify);
    }
}

export function ampSetOnce(key: string, value: any) {
    if(process.browser && window['amplitude']) {
        const firstPageVisit = new window['amplitude'].Identify().setOnce(key, value);
        window['amplitude'].getInstance().identify(firstPageVisit);
    }
}